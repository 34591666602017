
import {TrialItem} from '@/models/trial-item';
import {defineComponent, PropType} from 'vue'

export default defineComponent({
  name: 'TrialIntro',
  props: {
    trial: {
      type: Object as PropType<TrialItem>
    }
  }

})
