
import {TrialItem} from '@/models/trial-item';
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ParticipateTrialItem',
  props: {
    item: {
      type: Object as PropType<TrialItem>
    }
  }
})
