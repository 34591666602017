
import TrialAbout from '@/components/trials/TrialCompose/TrialAbout.vue';
// import TrialCriteria from '@/components/trials/TrialCompose/TrialCriteria.vue';
import TrialFooter from '@/components/trials/TrialCompose/TrialFooter.vue';
import TrialParticipate from '@/components/trials/TrialCompose/TrialParticipate.vue';
import TrialStudyLocations from '@/components/trials/TrialStudyLocations.vue';
import TrialIntro from '@/components/trials/TrialIntro.vue';
import TrialNav from '@/components/trials/TrialNav.vue';
import { defineComponent, PropType } from 'vue'
import { TrialItem } from '@/models/trial-item';

export default defineComponent({
  name: 'TrialComposeTemplate',
  components: {
      TrialIntro,
      TrialAbout,
      TrialNav,
      // TrialCriteria,
      TrialParticipate,
      TrialStudyLocations,
      TrialFooter
    },
  props: {
    trial: {
      type: Object as PropType<TrialItem>
    }, 
    scrolled:Boolean
  }

})
