
import TrialAbout from '@/components/trials/TrialAcappella/TrialAbout.vue';
import TrialParticipate from '@/components/trials/TrialAcappella/TrialParticipate.vue';

import TrialStudyLocations from '@/components/trials/TrialStudyLocations.vue';
import TrialIntro from '@/components/trials/TrialIntro.vue';

import TrialNav from '@/components/trials/TrialNav.vue';
import { defineComponent, PropType } from 'vue'
import { TrialItem } from '@/models/trial-item';

export default defineComponent({
  name: 'TrialComposeTemplate',
  components: {
      TrialIntro,
      TrialAbout,
      
      TrialNav,
      TrialParticipate,
      TrialStudyLocations,
    },
  props: {
    trial: {
      type: Object as PropType<TrialItem>
    }, 
    scrolled:Boolean
  }

})
