
import TrialComposeTemplate from '@/components/trials/TrialComposeTemplate.vue';
import TrialAcappellaTemplate from '@/components/trials/TrialAcappellaTemplate.vue';
import { defineComponent } from 'vue'
import trials from '@/data/trials.json'
import {useRoute} from 'vue-router';

export default defineComponent({
    name: 'TrialContainer',
    components: {
      TrialComposeTemplate,
      TrialAcappellaTemplate,
    },
    setup () {
        const route = useRoute();
        const trialId = route.params.trialId;
        const trial = trials.find(t => t.id === trialId);
        return {
          trial
        }
    },
    

    created: function() {
      window.addEventListener('scroll',this.checkMousePositionForHeader);
    },

    data: function(){
        return {scrolled: false}
    },
    unmounted: function() {
      window.removeEventListener('scroll', this.checkMousePositionForHeader);
    },
    methods: {
      checkMousePositionForHeader(){
         this.scrolled = window.scrollY > 123;
      }
  },
    

})
