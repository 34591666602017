
import {MapLocation,  StudyLocation} from '@/models/study-location';
import {TrialItem} from '@/models/trial-item';
import {defineComponent, PropType} from 'vue'
import { GoogleMap, Marker } from 'vue3-google-map'
import { ref, toRefs, computed, watch } from 'vue'

export default defineComponent({
  name: 'TrialStudyLocations',
  components: { GoogleMap, Marker },
  props: {
    trial: {
      type: Object as PropType<TrialItem>,
      required: true
    }
  },

  setup(props) {
    const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    const { trial } = toRefs(props)
    
    let virtualMarker = {
      

     
      mapLocation: { lat: 34.090069, lng: -75.182436 } ,
      status: 'active',
      address: 'Science 37, virtual site for all of the US',
      contact: ''
    }

    
  

    let defaultZoom = ref(trial.value.initialMapLocation.zoom);
    let defaultLatLon = ref(trial.value.initialMapLocation.mapLocation);
    let selectedLocation = ref(); // ref(trial.value.studyLocations[0]);
    const searchAddress = ref('');
    const mapRef = ref();
    let infoWindowXY = ref();
    let searchLocationDistance = ref();
    const mapsLibraries = ref(["geometry", "places"])

let google:any = (window as any).google

   watch(mapRef, () => {
     
        // if (mapRef?.value?.ready) {

          setTimeout(()=> {

            google= (window as any).google
            
              google.maps.event.addDomListener(mapRef.value.map, "click", ()=> {
                if(selectedLocation.value){
                  resetSelectedLocation();
                }
              });

              google.maps.event.addDomListener(mapRef.value.map, "center_changed", ()=> {
                if(selectedLocation.value){
                  updateInfoWindowLocation(selectedLocation.value)
                }

              });
              google.maps.event.addDomListener(mapRef.value.map, "zoom_changed", ()=> {
                if(selectedLocation.value){
                  resetSelectedLocation();
                }
              });


          }, 700)


    
        // }
      })

    const distanceInMiles = computed(() => {
      return Math.round(searchLocationDistance.value * 0.000621371192 * 100) / 100;
    });

    const toggleInfo =  (location: StudyLocation) => {
    selectedLocation.value = location;
    setTimeout(()=>{
      updateInfoWindowLocation(location)  
    }, 5)
  

    }
function resetSelectedLocation(){
   selectedLocation.value = null
   infoWindowXY.value = null
}
    function updateInfoWindowLocation(location:StudyLocation){

      const currentLocationXY = getPixelLocation(location.mapLocation)
      const pinHeight = 45
      const pinWidth = 27
      const infoWindowEl = document.getElementById('infoWindow')
      const infoWidth = (infoWindowEl) ?infoWindowEl.offsetWidth : 0;
      const infoHeight = (infoWindowEl) ?infoWindowEl.offsetHeight : 0;

      infoWindowXY.value =  {
       left:(currentLocationXY.x - ( ((infoWidth/2)-pinWidth/2) ))+'px',
       top:(currentLocationXY.y-(pinHeight+infoHeight))+'px'
     }

    }

     function getPixelLocation(currentLatLng:MapLocation) {

        const map = mapRef.value.map

        var scale = Math.pow(2, map.getZoom());


        // The NorthWest corner of the current viewport corresponds
        // to the upper left corner of the map.
        // The script translates the coordinates of the map's center point
        // to screen coordinates. Then it subtracts the coordinates of the
        // coordinates of the map's upper left corner to translate the 
        // currentLatLng location into pixel values in the <div> element that hosts the map.
        
        

        var nw = new google.maps.LatLng(
            map.getBounds().getNorthEast().lat(),
            map.getBounds().getSouthWest().lng()
        );

      
        
    
        // // Convert the nw location from geo-coordinates to screen coordinates
        var worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
        
        // // Convert the location that was clicked to screen coordinates also
        var selectedLatlng = new google.maps.LatLng(currentLatLng.lat,currentLatLng.lng);
        var worldCoordinate = map.getProjection().fromLatLngToPoint(selectedLatlng);

        var currentLocationXY = new google.maps.Point(
            Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
            Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
        );

        return currentLocationXY;
    }


    const search = () => {
      // console.log('SEARCH', searchAddress.value)
      const geocoder = new  mapRef.value.api.Geocoder();
      geocoder.geocode( { 'address': searchAddress.value}, function(results: any, status : any) {
        if (status == 'OK') {
          const searchLocation: MapLocation = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          }
          displayMinimalDistance(searchLocation);

        } else {
          console.log('Geocode was not successful for the following reason: ' + status);
        }
      });
    }

    const displayMinimalDistance = (searchLocation: MapLocation) => {
      const markers = trial.value.studyLocations.map(l => l.mapLocation);
       const minDistance = getMinimalDistance(searchLocation, markers);
       searchLocationDistance.value = minDistance.dist;
       
       selectedLocation.value = trial.value.studyLocations[minDistance.i];

       //update selctedLocation tooltip
      //  console.log('selectedLocation.value', selectedLocation.value)
       updateInfoWindowLocation(selectedLocation.value)
    }

    const getMinimalDistance = (location: MapLocation, locations: MapLocation[]) => {
      let minDistance = 20000000; // and now we will look for any shorter distance
      let minDistanceKey = -1;
      let dist;
      for(let i in locations) {
        dist = getDistance(location, locations[i]);
        if(dist < minDistance) {
          minDistance = dist;
          minDistanceKey = Number(i);
        }
      }
      return {i: minDistanceKey, dist: minDistance};
    }

    const getDistance = (source: MapLocation, destination: MapLocation) => {
      return mapRef.value.api.geometry.spherical.computeDistanceBetween(
          new mapRef.value.api.LatLng(source.lat, source.lng),
          new mapRef.value.api.LatLng(destination.lat, destination.lng)
      );
    }

    return {
      apiKey,
      defaultZoom,
      defaultLatLon,
      selectedLocation,
      searchAddress,
      mapRef,
      searchLocationDistance,
      mapsLibraries,
      distanceInMiles,
      toggleInfo,
      search,
      infoWindowXY,
      virtualMarker
    }
  },


})
