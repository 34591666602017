
import AboutClinicalTrials from '@/components/AboutClinicalTrials.vue';
import ParticipateTrialItem from '@/components/ParticipateTrialItem.vue';
import { defineComponent } from 'vue'
import trials from '@/data/trials.json'

export default defineComponent({
  name: 'HowToParticipate',
  components: {
    AboutClinicalTrials,
    ParticipateTrialItem
  },
  setup () {
    return {
      trials
    }
  }
})
